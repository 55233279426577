import React, { useState, Fragment } from "react";
import { Popover, PopoverBody } from "reactstrap";

import questionCircleIcon from "../../assets/images/question-circle-icon.svg";

const SelectRadioField = ({ labelHtml, formik, name, options, popoverContent, flexDirection = "row" }) => {
    const [showPopover, setShowPopover] = useState(false);

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    const togglePopover = () => {
        setShowPopover(!showPopover);
    }

    return (
        <div className="form-group">
            <fieldset
                className={`form-group__fieldset ${isInvalidClass()}`}
                id={name}
                {...formik.getFieldProps(name)}>

                <div className="form-group__field-wrapper form-group__field-wrapper--select-radio-field">

                    <label className="form-group__label">
                        {labelHtml}

                        {popoverContent && (
                            <Fragment>
                                <button className="form-group__field-trailing-button" id={name + "popover-target"} type="button" onClick={togglePopover}>
                                    <img className="form-group__field-trailing-icon img-fluid" src={questionCircleIcon} alt="" />
                                </button>
                                <Popover placement="top" isOpen={showPopover} target={name + "popover-target"} toggle={togglePopover}>
                                    <PopoverBody>{popoverContent}</PopoverBody>
                                </Popover>
                            </Fragment>
                        )}
                    </label>

                    <div className={`form-group__field-trailing-options form-group__field-trailing-options--${flexDirection === "row" ? "row" : "column"}`}>
                        {
                            options.map((option, index) => {
                                const key = option?.id ? `${ option.label }-${ index }-${ option.id }` : `${ option.label }-${ index }`;
                                const label = option.label;

                                return (
                                    <div className="form-group__field-trailing-option" key={key}>
                                        <input
                                            id={key}
                                            type="radio"
                                            name={name}
                                            value={option.value}
                                        />
                                        <label className="form-group__field-trailing-option-label" htmlFor={key}>
                                            {label}
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </fieldset>

            {formik.touched[name] && formik.errors[name] ? (
                <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
            ) : null}
        </div>
    );
};

export default SelectRadioField;
